import browserDetect from '../../../node_modules/browser-detect/dist/browser-detect.umd';

// easing functions http://goo.gl/5HLl8
export function easeInOutQuad(t, b, c, d) {
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}

// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
export const requestAnimFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  function(callback) {
    window.setTimeout(callback, 1000 / 60);
  };

export function scrollTo(to, callback, options) {
  var start = this
    ? options && options.top
      ? this.scrollTop
      : this.scrollLeft
    : document.documentElement.scrollTop || document.body.scrollTop;
  var change = to - start,
    currentTime = 0,
    increment = 20,
    duration = 500,
    self = this;

  if (arguments.length < 3) {
    duration = 500;
  }

  var animateScroll = function() {
    // increment the time
    currentTime += increment;
    // find the value with the quadratic in-out easing function
    var val = easeInOutQuad(currentTime, start, change, duration);

    if (self) {
      if (options && options.top) {
        self.scrollTop = val;
      } else {
        self.scrollLeft = val;
      }
    } else {
      // move the document container
      document.documentElement.scrollTop = val;
      document.body.scrollTop = val;
    }

    // do the animation unless its over
    if (currentTime < duration) {
      requestAnimFrame(animateScroll);
    } else {
      if (typeof callback === 'function') {
        // the animation is done so lets callback
        callback();
      }
    }
  };
  animateScroll();
}

export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function nodeListToArray(nodeList) {
  if (!(nodeList instanceof NodeList)) return;
  const array = [];
  for (let i = 0; i < nodeList.length; i++) {
    array.push(nodeList[i]);
  }
  return array;
}

/**
 * Returns path to node
 * @param {DOMNode} elem
 */
export function getPath(elem) {
  const path = [];

  while (elem) {
    path.push(elem);
    elem = elem.parentElement;
  }

  return path;
}

/* Closest Polyfill */
if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

/**
 * Get url params as an object
 */
export function getUrlVars(url = window.location.href) {
  var vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
    vars[key] = value;
  });
  return vars;
}

/* Returns node element
 * @param {string} selector
 * @param {DOMNode} parent
 */
export function qs(selector, parent = document) {
  return parent.querySelector(selector);
}

/**
 * Returns array form for node elements
 * @param {string} selector
 * @param {DOMNode} parent
 */
export function qsa(selector, parent = document) {
  const els = parent.querySelectorAll(selector);
  const a = [];
  for (let i = 0; i < els.length; i++) {
    a.push(els[i]);
  }
  return a;
}

/**
 * Returns website {string} if it is executing from razopay.com
 * Returns beta-website {string} if it is executing from beta site.
 */

export function beingExecdFrom() {
  if (location.hostname === 'razorpay.com') {
    return 'website';
  } else if (location.hostname.indexOf('betasite.razorpay.com') > -1) {
    return 'beta-website';
  } else if (location.hostname.indexOf('gammasite.razorpay.com') > -1) {
    return 'gamma-website';
  }
}

export function throttle(func, limit) {
  let lastFunc;
  let lastRan;
  return function() {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function() {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
}

export function triggerAPI(url, method, callback, payload = '') {
  var xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    if (this.status) {
      callback(xhttp.response, xhttp.readyState);
    }
  };
  xhttp.onerror = function() {
    console.log('Something went wrong while gathering the request data.');
  };
  xhttp.open(method, url, true);
  if (payload && method === 'POST') {
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.send(JSON.stringify(payload));
  } else {
    xhttp.send();
  }
}

export function getBrowserDetails() {
  const browser = browserDetect();
  let connection_type = null;
  if (
    typeof navigator !== 'undefined' &&
    'connection' in navigator &&
    'effectiveType' in navigator.connection
  ) {
    connection_type = navigator.connection.effectiveType;
  }
  return {
    device: browser.mobile ? 'mobile' : 'web',
    browser: browser.name,
    browser_version: browser.version,
    os: browser.os,
    screen_height: window.screen && window.screen.height,
    screen_width: window.screen && window.screen.width,
    connection_type
  };
}

export function isInViewport(element) {
  var elementTop = $(element).offset().top;
  var elementBottom = elementTop + $(element).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
}

/*
  Checks if element is 'percentVisible' percent visible in viewport
  headerHeight = Height of any element at the top which is blocking the view of the element
  footerHeight = Height of any element at the bottom which is blocking the view of the element
*/
export const isElementXPercentInViewport = (
  element,
  percentVisible,
  headerHeight = 0,
  footerHeight = 0
) => {
  let elementTop,
    elementHeight,
    elementBottom,
    percentCutFromTop,
    percentCutFromBottom;
  let rect, windowHeight;

  rect = element.getBoundingClientRect();
  windowHeight = window.innerHeight || document.documentElement.clientHeight;

  elementTop = rect.top - headerHeight >= 0 ? 0 : headerHeight - rect.top;
  elementHeight = rect.height;
  percentCutFromTop = (elementTop / elementHeight) * 100;

  elementBottom = rect.bottom + footerHeight - windowHeight;
  percentCutFromBottom = (elementBottom / elementHeight) * 100;

  return !(
    Math.floor(100 - percentCutFromTop) < percentVisible ||
    Math.floor(100 - percentCutFromBottom) < percentVisible
  );
};

export const isTouchEnabled = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

export const tapElement = (element, func) => {
  element.addEventListener('touchstart', () => {
    element.addEventListener('touchend', func);
  });
  element.addEventListener('touchmove', () => {
    element.removeEventListener('touchend', func);
  });
};

export function readUTMCookie() {
  let firstUtm = '';
  let lastUtm = '';
  let firstPage = '';
  let finalPage = '';
  let website = '';
  let isNewUser = false;

  try {
    const rzpUtmCookie = getCookie('rzp_utm');
    const parsedCookie = JSON.parse(rzpUtmCookie);

    // Populate first and last utm
    // Put first utm object to firstUtm key
    // and second utm object to lastUtm key
    // if second utm object is not present
    // populate first utm object to lastUtm key
    if (
      parsedCookie &&
      parsedCookie.attributions &&
      parsedCookie.attributions.length > 0
    ) {
      const attributions = parsedCookie.attributions;
      firstUtm = attributions[0];
      if (attributions[1]) {
        lastUtm = attributions[1];
      } else {
        lastUtm = attributions[0];
      }
    }
    firstPage =
      parsedCookie && parsedCookie.first_page ? parsedCookie.first_page : '';
    finalPage =
      parsedCookie && parsedCookie.final_page ? parsedCookie.final_page : '';
    website = parsedCookie && parsedCookie.website ? parsedCookie.website : '';
    isNewUser =
      parsedCookie && parsedCookie.new_user ? parsedCookie.new_user : false;
  } catch (e) {
    console.log('rzp_utm cookie is malformed');
  }

  return {
    firstUtm,
    lastUtm,
    firstPage,
    finalPage,
    website,
    isNewUser
  };
}

export function isMobile() {
  return window.innerWidth < 1024;
}

/**
 * Utility function to find element with a className
 * Return the element containing the class
 */
export function findEl(el, classname) {
  if (el.classList.contains(classname)) {
    return el;
  } else if (el.parentNode.classList.contains(classname)) {
    return el.parentNode;
  }
  return null;
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts[1].split(';')[0];
  return null;
}

export function setCookie(name, value, expiryDays = 365) {
  const date = new Date();
  date.setDate(date.getDate() + expiryDays);
  const expires = date.toUTCString();
  document.cookie = `${name}=${value};domain=.razorpay.com;expires=${expires};path=/;`;
}

export function toTitleCase(str) {
  let stringArr = str.toLowerCase().split(' ');
  for (let i = 0; i < stringArr.length; i++) {
    stringArr[i] =
      stringArr[i].charAt(0).toUpperCase() + stringArr[i].substr(1);
  }
  return stringArr.join(' ');
}

export function uuid() {
  return 'xxxx-xxxx-xxxx-xxxx'.replace(/[x]/g, function(item) {
    let random = Math.floor(Math.random() * 16);
    return random.toString(16);
  });
}

export function gtag() {
  dataLayer.push(arguments);
}

export function customeEventsToGTM(customEvent) {
  if (window.dataLayer) {
    dataLayer.push(customEvent);
  }
}

export function getExperimentID(prodExpId, BetaExpId) {
  if (window.location.host === 'razorpay.com') {
    return prodExpId;
  } else {
    return BetaExpId;
  }
}

export function useExperiment(prodKey, betaKey, expCallback) {
  gtag('event', 'optimize.callback', {
    name: getExperimentID(prodKey, betaKey),
    callback: value => {
      expCallback(value);
    }
  });
}

export function between(x, min, max) {
  return x >= min && x <= max;
}

export const getBox = el => el.getBoundingClientRect();
