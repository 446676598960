import { qs } from './util';
import { emitSegment } from './emitSegment';
import lj from '../lumberjack';

export function trackSegment(e, eventName, otherProperties, toCleverTap) {
  let properties = Object.assign(
    {
      anonymous_id: localStorage.getItem('ajs_anonymous_id'),
      device_type: isMobile() ? 'mweb' : 'dweb',
      url: location.href,
      section: e ? e.currentTarget.classList : '',
      experiment_ID: sessionStorage.getItem('experimentId') || '',
      pageUrl: location.href.split('?')[0],
      source: isMobile ? 'Mobile Website' : 'Website',
      slug: window.location.origin + window.location.pathname,
      mode: 'null',
      userRole: 'null',
      userId: 'null',
      merchantId: 'null'
    },
    otherProperties
  );
  emitSegment(eventName, properties, toCleverTap);
}

export function trackSegmentAndLJ(e, eventName, otherProperties, toCleverTap) {
  trackSegment(e, eventName, otherProperties, toCleverTap);
  lj.pushEvents({
    event_name: eventName,
    device_type: isMobile() ? 'mweb' : 'dweb',
    event_type: typeOfEvents[e.type] || 'clicked',
    properties: otherProperties
  });
}

export function isMobile() {
  if (
    window.razorpayAnalytics &&
    window.razorpayAnalytics.utils &&
    window.razorpayAnalytics.utils.getBrowserDetails &&
    window.razorpayAnalytics.utils.getBrowserDetails().device === 'mobile'
  ) {
    return true;
  } else {
    return false;
  }
}

export function selectMenuItem(itemName) {
  let selector = null;
  switch (itemName) {
    case 'Products':
      selector = '#nav .nav-item.nav-menu.nav1 .nav-category';
      break;
    case 'Banking':
      selector = '#nav .nav-item.nav-menu.nav2 .nav-category';
      break;
    case 'Resources':
      selector = '#nav .nav-item.nav-menu.nav3 .nav-category';
      break;
    case 'Support':
      selector = '#nav .nav-item.nav-menu.nav4 .nav-category';
      break;
    case 'Pricing':
      selector =
        '#nav .nav-item.nav-link.nav-pricing-link.nav5.pricing .nav-category';
      break;
    case 'Docs':
      selector = '#nav .link.docs';
      break;
    case 'Integration':
      selector = '#nav .link.integrations';
      break;
    case 'APIReference':
      selector = '#nav .link.api-reference';
      break;
    case 'Capital':
      selector = '#nav .link.capital';
      break;
    case 'PG':
      selector = '#nav .nav-item.nav-menu.nav1 .link.pg';
      break;
    case 'PP':
      selector = '#nav .nav-item.nav-menu.nav1 .link.pp';
      break;
    case 'PL':
      selector = '#nav .nav-item.nav-menu.nav1 .link.pl';
      break;
    case 'PB':
      selector = '#nav .nav-item.nav-menu.nav1 .link.pb';
      break;
    case 'Subscriptions':
      selector = '#nav .nav-item.nav-menu.nav1 .link.subs';
      break;
    case 'SmartCollect':
      selector = '#nav .nav-item.nav-menu.nav1 .link.sc';
      break;
    case 'UPIAuto':
      selector = '#nav .nav-item.nav-menu.nav1 .link.upiauto';
      break;
    case 'Freelance':
      selector = '#nav .link.freelance';
      break;
    case 'Route':
      selector = '#nav .nav-item.nav-menu.nav1 .link.route';
      break;
    case 'CRED':
      selector = '#nav .nav-item.nav-menu.nav1 .link.cred';
      break;
    case 'Invoices':
      selector = '#nav .nav-item.nav-menu.nav1 .link.invoices';
      break;
    case 'PreCOD':
      selector = '#nav .nav-item.nav-menu.nav1 .link.preCOD';
      break;
    case 'UPI':
      selector = '#nav .nav-item.nav-menu.nav1 .link.upi';
      break;
    case 'Affordability':
      selector = '#nav .nav-item.nav-menu.nav1 .link.afford';
      break;
    case 'EPOS':
      selector = '#nav .nav-item.nav-menu.nav1 .link.epos';
      break;
    case 'SettlementGuide':
      selector = '#nav .link.settlement-guide';
      break;
    case 'KnowlegdeBase':
      selector = '#nav .nav-item.nav-menu.nav4 .link.knowlegde-base';
      break;
  }
  return qs(selector);
}

const typeOfEvents = {
  click: 'clicked',
  mouseover: 'viewed',
  change: 'success',
  scroll: 'viewed',
  DOMContentLoaded: 'rendered'
};

//to push events to lumberjack and segment
export function trackLumberjackAndSegment(
  e,
  eventName,
  section,
  other_properties,
  toCleverTap
) {
  let properties = Object.assign(
    {
      anonymousId: localStorage.getItem('ajs_anonymous_id'),
      url: location.href,
      section: section || e.currentTarget.classList,
      experiment_ID: sessionStorage.getItem('experimentId') || ''
    },
    other_properties
  );
  lj.pushEvents({
    event_name: eventName,
    device_type: isMobile() ? 'mweb' : 'dweb',
    event_type: typeOfEvents[e.type] || 'clicked',
    properties: properties,
    toCleverTap: toCleverTap
  });
}

export function selectPageElement(elementName) {
  let selector = null;
  switch (elementName) {
    case 'X-Nav-Signup':
      selector = '#nav .right .btn-icon.nav-early-access';
      break;
    case 'X - Get Started Now':
      selector = '#scene #headings .x-btn.btn-icon';
      break;
    case 'X-Pricing-Basic Signup btn':
      selector = '#x-pricing-block .plan-wrapper.basic #signup-btn';
      break;
    case 'X-Pricing-Pro Signup btn':
      selector = '#x-pricing-block .plan-wrapper.pro .x-btn.btn-icon';
      break;
    case 'X-Xperience Signup btn':
      selector = '#page #scene .screen_9 a.x-btn.btn-icon';
      break;
    case 'X-Current-Account Get Started Now':
      selector = '#page #current-account .banner--section a.x-btn.btn-icon';
      break;
    case 'X-Current-Account apply-now-section Get Started Now':
      selector = '#page #current-account .apply-now-section a.x-btn.btn-icon';
      break;
    case 'X-TDS-Online-Payment Signup btn':
      selector = '#page #x-taxpayments-lite-app .screen-1 a.x-btn.btn-icon';
      break;
    case 'X-Vendor-Payments Banner Signup btn':
      selector = '#vp3 #page #banner-vp .cta-wrapper a.x-btn.btn-icon';
      break;
    case 'X-Vendor-Payments automate-block Signup btn':
      selector = '#vp3 #page .dark-section-2 .automate-block a.x-btn.btn-icon';
      break;
    case 'X-Vendor-Payments faqs-section Signup btn':
      selector = '#vp3 #page #faqs-section .automate-block a.x-btn.btn-icon';
      break;
    case 'X-Payouts banner Signup btn':
      selector = '#page #x-payouts .banner--section a.x-btn.btn-icon';
      break;
    case 'X-Payouts-Links banner Signup btn':
      selector = '#payout-links #page .hero .container .x-btn.btn-icon';
      break;
    case 'X-Payouts-Links pricing-basic Signup btn':
      selector =
        '#payout-links #x-pricing-block .plan-wrapper.basic a#signup-btn';
      break;
    case 'X-Payouts-Links pricing-pro Signup btn':
      selector =
        '#payout-links #x-pricing-block .plan-wrapper.pro a.x-btn.btn-icon';
      break;
    case 'X-Tax-Payments Get Started Now':
      selector = '#page #x-taxpayments-app .tax-payment-links a.x-btn.btn-icon';
      break;
    case 'X-Tax-Payments steps-section Get Started Now':
      selector = '#page #x-taxpayments-app .howOpfinWorks a.x-btn.btn-icon';
      break;
    case 'X-Accounting-Payouts Xperience Signup btn':
      selector = '#page .experience-future-of-banking a.x-btn.btn-icon';
      break;
    case 'X-Accounting-Payouts-Quickbooks Signup btn':
      selector = '#page .qbs-cta-wrapper a.x-btn.btn-icon';
      break;
    case 'X-Home FAQs Q1':
      selector = '#page #faqs div.faqs-tab-title.nomob:nth-child(1)';
      break;
    case 'X-Home FAQs Q2':
      selector = '#page #faqs div.faqs-tab-title.nomob:nth-child(3)';
      break;
    case 'X-Home FAQs Q3':
      selector = '#page #faqs div.faqs-tab-title.nomob:nth-child(5)';
      break;
    case 'X-Home FAQs Q4':
      selector = '#page #faqs div.faqs-tab-title.nomob:nth-child(7)';
      break;
    case 'X-Home FAQs Q5':
      selector = '#page #faqs div.faqs-tab-title.nomob:nth-child(9)';
      break;
    case 'X-Home FAQs Q6':
      selector = '#page #faqs div.faqs-tab-title.nomob:nth-child(11)';
      break;
    case 'X-Current-Account Accounting-Integration-cta':
      selector =
        '#current-account .badges-section a[href="/x/accounting-payouts/"]';
      break;
    case 'X-Current-Account Vendor-Payments-cta':
      selector =
        '#current-account .badges-section a[href="https://razorpay.com/x/vendor-payments/"]';
      break;
    case 'X-Current-Account Tax-Payments-cta':
      selector =
        '#current-account .badges-section a[href="https://razorpay.com/x/tax-payments/"]';
      break;
    case 'X-Current-Account Secure-Access-cta':
      selector =
        '#current-account #collabsiable-accordian div[href="accordian-1"]';
      break;
    case 'X-Current-Account Payment-Security-cta':
      selector =
        '#current-account #collabsiable-accordian div[href="accordian-2"]';
      break;
    case 'X-Current-Account Account-Stability-cta':
      selector =
        '#current-account #collabsiable-accordian div[href="accordian-3"]';
      break;
    case 'X-Home Demo-video cta':
      selector = '#scene #headings a.nomob.watch-video-btn';
      break;
    case 'X-Home View-Demo cta':
      selector = '#scene #headings a.nomob.view-demo-btn';
      break;
    case 'X-Home Ecosystem Current-account':
      selector = '#x-ecosystem-diagram .diagram .col-2 .box.center-box';
      break;

    case 'X-Home Ecosystem Payment-gateway':
      selector = '#x-ecosystem-diagram .diagram .col-1 .box.box-1';
      break;

    case 'X-Home Ecosystem Corporate-card':
      selector = '#x-ecosystem-diagram .diagram .col-2 .box.box-6';
      break;

    case 'X-Home Ecosystem Instant-loans':
      selector = '#x-ecosystem-diagram .diagram .col-2 .box.box-7';
      break;

    case 'X-Home Ecosystem Standard-and-bulk-payouts':
      selector = '#x-ecosystem-diagram .diagram .col-3 .box.box-8';
      break;

    case 'X-Home Ecosystem Payout-links':
      selector = '#x-ecosystem-diagram .diagram .col-3 .box.box-9';
      break;

    case 'X-Home Ecosystem Vendor-Payments':
      selector = '#x-ecosystem-diagram .diagram .col-3 .box.box-10';
      break;

    case 'X-Home Ecosystem Payroll-and-Insurance':
      selector = '#x-ecosystem-diagram .diagram .col-3 .box.box-11';
      break;
    case 'X-Home Ecosystem tax-payments':
      selector = '#x-ecosystem-diagram .diagram .col-3 .box.box-12';
      break;
    case 'X-Home Save time & cost vendor-payments':
      selector =
        '#scene .automate-payment-block .bullet-points-list .badges-section a[data-section="Vendor Payments"]';
      break;
    case 'X-Home Save time & cost payroll':
      selector =
        '#scene .automate-payment-block .bullet-points-list .badges-section a[data-section="Payroll"]';
      break;
    case 'X-Home Scale Easily bulk payouts':
      selector =
        '#scene .bulk-payment-block .bullet-points-list .badges-section a[data-section="Bulk Payouts"]';
      break;
    case 'X-Home Gain control & visibility Accounting integrations':
      selector =
        '#scene .financial-report-block .bullet-points-list .badges-section a[data-section="Accounting Integrations"]';
      break;
    case 'X-Home Manage cash flows RazorpayX corporate credit':
      selector =
        '#scene .manage-cash-flows .bullet-points-list .badges-section a[data-section="RazorpayX Corporate Credit Card"]';
      break;
    case 'X Integrations tally-cta':
      selector = '#scene #accounting-tools .content-section img[alt="Tally"]';
      break;
    case 'X Integrations quickBooks-cta':
      selector =
        '#scene #accounting-tools .content-section img[alt="QuickBooks"]';
      break;
    case 'X Integrations ZohoBooks-cta':
      selector =
        '#scene #accounting-tools .content-section img[alt="ZohoBooks"]';
      break;
    case 'Pricing X basic view more':
      selector = '#x-pricing-block .basic .view-more-plan-btn';
      break;
    case 'Pricing X pro view more':
      selector = '#x-pricing-block .pro .view-more-plan-btn';
      break;
    case 'Payouts Page Banner section View Demo CTA':
      selector = '#page #x-payouts .banner--section a.view-demo';
      break;
    case 'Payouts Page Demo Preview GIF':
      selector = '#page #x-payouts .x-demo-block video';
      break;
    case 'Payouts Page Demo Preview CTA':
      selector = '#page #x-payouts .x-demo-block.nomob .x-btn.btn-icon';
      break;
    case 'Payouts Page mweb Demo Preview CTA':
      selector = '#page #x-payouts .x-demo-block.mob .x-btn.btn-icon';
      break;
    case 'Payouts Page Automate payouts view Api Integrations':
      selector =
        '#scene .business-block .bullet-points-list .badges-section a[data-section="View API documentation"]';
      break;
    case 'Payouts Page Accounting integrations view Accounting integrations':
      selector =
        '#scene .finantial-reports-block .bullet-points-list .badges-section a[data-section="Accounting Integrations"]';
      break;
    case 'TaxPayments Hero properties Try free tool':
      selector = '#scene .standard-hero-section a.try-now';
      break;
    case 'TaxPayments Hero properties play video':
      selector = '#scene .standard-hero-section .right-bg #play-video';
      break;
    case 'TaxPayments Page Accounting integrations vendor payments cta':
      selector =
        '#scene .screen-2 .bullet-points-list .badges-section a[data-section="Vendor Payments"]';
      break;
    case 'TaxPayments Page Accounting integrations api based payouts cta':
      selector =
        '#scene .screen-2 .bullet-points-list .badges-section a[data-section="API based Payouts"]';
      break;
    case 'Accounting-Payouts Tally-Payouts Get-early-access-cta':
      selector = '#accounting-payouts .hero .container button.strip-btn';
      break;
    case 'Accounting-Payouts Tally-Payouts Full-name-entered':
      selector =
        '#accounting-payouts-modal .modal-content input[name="signup_contact_name"]';
      break;
    case 'Accounting-Payouts Tally-Payouts Email-entered':
      selector = '#accounting-payouts-modal .modal-content input[name="email"]';
      break;
    case 'Accounting-Payouts Tally-Payouts Mobile-entered':
      selector =
        '#accounting-payouts-modal .modal-content input[name="signup_contact_mobile"]';
      break;
    case 'Accounting-Payouts Tally-Payouts Early-access-submit':
      selector =
        '#accounting-payouts-modal .modal-content input[type="submit"]';
      break;
    case 'X-Mobile-App Google_play_cta mob':
      selector = '.banner--section-right img[alt="play store"]';
      break;
    case 'X-Mobile-App Apple_app_store_cta mob':
      selector = '.banner--section-right img[alt="apple store"]';
      break;
    case 'X-Mobile-App Google_play_cta web':
      selector = '.banner--section-left img[alt="play store"]';
      break;
    case 'X-Mobile-App Apple_app_store_cta web':
      selector = '.banner--section-left img[alt="apple store"]';
      break;
    case 'CA page mobile_learn_more_cta web':
      selector =
        '#current-account .ftx-mobile-app--section-right.nomob .right-container a[data-lytics-label="Sign Up"]';
      break;
    case 'CA page mobile_learn_more_cta mob':
      selector =
        '#current-account .ftx-mobile-app--section-right.mob a[data-lytics-label="Sign Up"]';
      break;
    case 'Payouts page mobile_learn_more_cta web':
      selector =
        '#x-payouts .ftx-mobile-app--section-right.nomob .right-container a[data-lytics-label="Sign Up"]';
      break;
    case 'Payouts page mobile_learn_more_cta mob':
      selector =
        '#x-payouts .ftx-mobile-app--section-right.mob a[data-lytics-label="Sign Up"]';
      break;
    case 'X-Home page mobile_learn_more_cta web':
      selector =
        '#x .ftx-mobile-app--section-right.nomob .badges-section a[data-section="RazorpayX mobile App"]';
      break;
    case 'X-Home page mobile_learn_more_cta mob':
      selector =
        '#x .ftx-mobile-app--section-right.mob .badges-section a[data-section="RazorpayX mobile App"]';
      break;
    case 'X Vendor-Payments Email-Integration Top-nav-bar Sign-Up-Cta-clicked':
      selector = '.email-integration #nav div.container ul div.right li button';
      break;
    case 'X Vendor-Payments Email-Integration Hero-section Sign-Up-Cta-clicked':
      selector = '.email-integration #hero a.x-btn.btn-icon';
      break;
    case 'X Vendor-Payments Email-Integration Get-started-card Sign-Up-Cta-clicked':
      selector =
        '.email-integration #customer-box div.cta-link a.x-btn.btn-icon';
      break;
    case 'CS Get in touch cta clicked mob':
      selector = '.mob .enterprise #signup-btn';
      break;
    case 'CS Get in touch cta clicked nomob':
      selector = '.nomob .enterprise #signup-btn';
      break;
    case 'ca eligible Sign Up Cta clicked':
      selector = '#razorpay-x-contact-sales-ca-eligible .flex-button .primary';
      break;
    case 'ca eligible CS ca demo cta clicked':
      selector =
        '#razorpay-x-contact-sales-ca-eligible .flex-button .secondary';
      break;
    case 'ca not eligible Sign Up Cta clicked':
      selector =
        '#razorpay-x-contact-sales-ca-not-eligible .flex-button .primary';
      break;
    case 'CS non-ca know more cta clicked':
      selector =
        '#razorpay-x-contact-sales-ca-not-eligible .flex-button .secondary';
      break;
    case 'CS pg login cta clicked':
      selector =
        '#razorpay-x-contact-sales-payment-gateway .flex-button .primary';
      break;
    case 'CS refund cta clicked':
      selector = '#razorpay-x-contact-sales-refunds .flex-button .primary';
      break;
    case 'CS form viewed':
      selector = '#razorpay-x-contact-sales-modal';
      break;
    case 'CS ca eligible screen viewed':
      selector = '#razorpay-x-contact-sales-ca-eligible';
      break;
    case 'CS ca not eligible screen viewed':
      selector = '#razorpay-x-contact-sales-ca-not-eligible';
      break;
    case 'CS PG screen viewed':
      selector = '#razorpay-x-contact-sales-payment-gateway';
      break;
    case 'CS refund screen viewed':
      selector = '#razorpay-x-contact-sales-refunds';
      break;
    case 'CS form closed clicked':
      selector = '#contact-sales-form-close';
      break;
    case 'neobank-navbar-nomob':
      selector = '.x_clean_nav_report .nav-item.nomob';
      break;
    case 'neobank-navbar-mob':
      selector = '.x_clean_nav_report .nav-item.mob';
      break;
    case 'neobank-banner-report':
      selector = '.neobank-report .banner .x-btn';
      break;
    case 'neobank-features-report':
      selector = '.neobank-report .key-points .x-btn';
      break;
    case 'neobank-revolution-report-nomob':
      selector = '.neobank-report .neobank-revolution .nomob .x-btn';
      break;
    case 'neobank-revolution-report-mob':
      selector = '.neobank-report .neobank-revolution .mob .x-btn';
      break;
    case 'neobank-footer-signup':
      selector = '.neobank-report .bottom-footer-section .x-btn.btn-icon';
      break;
    case 'razorpay-x-neobank-report-modal':
      selector = '#razorpay-x-neobank-report-modal .modal-content';
      break;
    case 'razorpay-x-neobank-report-modal-close':
      selector = '#razorpay-x-neobank-report-modal .modal-content .close';
      break;
    case 'razorpay-x-neobank-report-modal-submit':
      selector =
        '#razorpay-x-neobank-report-modal .modal-content #submit-details-btn';
      break;
    case 'demo-mode-entry-point-cta':
      selector = '#current-account .current-account-demo .x-btn';
      break;
    case 'nav-demo-mode-entry-nomob':
      selector = '.nav-demo-mode-entry';
      break;
    case 'nav-x-demo-mode-entry-nomob':
      selector = '.nav-x-demo-mode-entry';
      break;
    case 'footer-demo-mode-entry-nomob':
      selector = '.footer-demo-mode-entry';
      break;
    case 'footer-x-demo-mode-entry-nomob':
      selector = '.footer-x-demo-mode-entry';
      break;
  }
  return qs(selector);
}
