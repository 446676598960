import { getCookie, readUTMCookie, setCookie, uuid } from './modules/util';

let currentPage =
  window.location.hostname.toLowerCase() +
  (window.location.pathname || '').toLowerCase();

const getIsLandingPageUser = (currentPage, rzpUtmCookie) => {
  // check is the current page is the first ever page visited by the
  // user on razorpay.com. Populate the same in the cookie `isLandingPageUser`
  // isLandingPageUser will be true only for the first ever page visit of the user
  // the subsequent visit on the same page, isLandingPageUser will be false
  let isLandingPageUser;
  const isLandingPageUserInCookie = getCookie('isLandingPageUser');
  if (
    rzpUtmCookie.isNewUser &&
    currentPage === rzpUtmCookie.firstPage &&
    !isLandingPageUserInCookie
  ) {
    setCookie('isLandingPageUser', true);
    isLandingPageUser = true;
  } else {
    isLandingPageUser = false;
  }
  return isLandingPageUser;
};

const getFirstPageInSession = currentPage => {
  // Store the first page url when the user lands on razorpay.com
  // This should not change when the user navigates to other pages
  let firstPageURLInSession;
  try {
    firstPageURLInSession = sessionStorage.getItem('first_page');
    if (!firstPageURLInSession) {
      sessionStorage.setItem('first_page', currentPage);
      firstPageURLInSession = currentPage;
    }
  } catch (e) {
    firstPageURLInSession = currentPage;
  }

  return firstPageURLInSession;
};

/**
 * A function to return session related properties
 * commonSessionId : a common session id between website & merchant dashboard
 * isLandingPageSession : tells if the current page is the first page in the session
 * Here session refers to a session between website & merchant dashboard
 * @returns {{commonSessionId: string, isLandingPageSession: boolean}}
 */
export const getSessionInfo = () => {
  let commonSessionId = '';
  let isLandingPageSession = false;
  try {
    commonSessionId = sessionStorage.getItem('commonSessionId');
    const commonSessionIdCookie = getCookie('commonSessionId');
    const isDashboardSession = document.referrer.includes(
      'dashboard.razorpay.com'
    );

    /*
    Check if there is no common session present,
    if yes, then populate the same
    if no, check if the session started from dashboard
    if yes, use dashboard's session id from the cookie
    and make isLandingPageSession as false because the
    session started from dashboard and landing page was dashboard
    if no, create a new session id and populate the same in the cookie,
    so that dashboard can consume it and make isLandingPageSession as
    true bcz the session started on website
     */
    if (!commonSessionId) {
      if (isDashboardSession) {
        commonSessionId = commonSessionIdCookie;
        isLandingPageSession = false;
      } else {
        sessionStorage.setItem('commonSessionId', uuid());
        commonSessionId = sessionStorage.getItem('commonSessionId');
        setCookie('commonSessionId', commonSessionId);
        isLandingPageSession =
          getFirstPageInSession(currentPage) === currentPage;
      }
    }
  } catch (e) {
    // Silently ignore
  }

  return {
    commonSessionId,
    isLandingPageSession
  };
};

export const getLjEventProps = () => {
  let rzpUtmCookie = readUTMCookie();

  const firstPageURLInSession = getFirstPageInSession(currentPage);

  const isFirstPageSession =
    rzpUtmCookie && rzpUtmCookie.finalPage === firstPageURLInSession;

  const isFirstPageUser =
    rzpUtmCookie && rzpUtmCookie.firstPage === firstPageURLInSession;

  const isNewUser = rzpUtmCookie.isNewUser;
  const firstPage = rzpUtmCookie.firstPage;
  const isLandingPageUser = getIsLandingPageUser(currentPage, rzpUtmCookie);
  const firstUtm = rzpUtmCookie.firstUtm;
  const lastUtm = rzpUtmCookie.lastUtm;

  return {
    isFirstPageSession,
    isFirstPageUser,
    isNewUser,
    firstPage,
    isLandingPageUser,
    firstUtm,
    lastUtm
  };
};
